@charset "utf-8";



// Our variables
$base-font-family: 'Source Han Sans','メイリオ',Meiryo,'ＭＳ Ｐゴシック',Helvetica, Arial, sans-serif;
$ex-font-family: "Times New Roman", "游明朝", YuMincho, "Hiragino Mincho ProN", Meiryo, serif;
$base-font-size:   13px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.8em;

$spacing-unit:     30px;

$text-color:       #333;
$background-color: #EEF2E4;
$brand-color:      #8612A6;
$person-color:     #88a336;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

$on-palm:          400px;
$on-laptop:        500px;

$images:     "../images/";

// Using media queries with like this:
// @include media-query($palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

h1{font-family: $ex-font-family}
.post h1{font-family: $ex-font-family}
.page h1{font-family: $ex-font-family}
.page h2{font-family: $ex-font-family}
.font-bilbo{letter-spacing:2px;font-size:1.4rem;font-family: 'Bilbo'}
.font-s{font-size:0.8em;}
.font-l{font-size:1.1em;}
.font-w{font-weight: bold}
.text-c{text-align: center}
.text-r{text-align: right}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        url(http://fonts.googleapis.com/css?family=Bilbo);
;
