/**
 * Site header
 */
#wrap{
    width: 96%;
    max-width: 550px;
    margin: 0 auto;
}

.site-header{
    position: relative;
    margin: 15px auto;
    @extend %clearfix;
    h1{
        width: 75px;
        height: 30px;
        background: url(#{$images}logo.png);
        background-size: cover;
        text-indent: -9999px;
        margin: 0 30px 0 0;
        float: left;
        a{
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    nav{
        margin:0 auto;
        float:left;
        text-align: center;
        a{
            display: inline-block;
            vertical-align: bottom;
            margin:0 15px 0 0;
            line-height: 45px;
            font-size: 1.2rem;
        }
    }
}

.post-list{
    h1{
        font-size: 1.5em;
        font-weight: normal;
        margin:50px 0 10px !important;
    }
    ul{
        li{
            margin: 0 0 10px;
        }
    }
}

/**
 * Site footer
 */
.site-footer {
    border-top: 1px dotted $grey-color;
    padding: 1em 0 0;
}

/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 10px;
    article{
        h1{
            text-align: center;
        }
        h2{
            margin:40px 0 10px;
        }
        .post-state{
            font-size: 0.8rem;
            color:$grey-color;
            display: block;
            margin:15px 0;
            text-align: center;
        }
    }
    #frame_wrap{
        .frame{
            margin:0 0 55px;
        }
    }
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}


/**
 * Posts
 */
.post-header {
        h1{
            text-align: center;
        }
        .post-state{
            font-size: 0.8rem;
            color:$grey-color;
            display: block;
            margin:15px 0;
            text-align: center;
        }
}
.post-footer {
            font-size: 0.8rem;
            color:$grey-color;
            display: block;
            margin:15px 0;
            text-align: center;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;
}

.post-content {

}

#setting{
    width: 280px;
    border:2px solid #ccc;
    font-size:13px;
    line-height: 1.7em;
    background: #fff;
    color:#666;
    position: fixed;
    padding:10px;
    top:10%;
    left:10px;
}
#setting input[type="range"]{
    width: 100%;
}

#mailform table{
    width: 100%;
    th{
        text-align: right;
        vertical-align: top;
    }
    td{
        width: 80%;
        input[type="text"]{
            width: 100%;
        }
        textarea{
            width: 100%;
        }
        input[type="submit"]{
            float:right;
        }
    }
}

#at{
    z-index: 35;
    position: fixed;
    top:0;
    width: 100%;
    height: 100%;
    background: #000;
    color:#ccc;
    .inr{
        width:100%;
        max-width:480px;
        height: 100%;
        margin:15% auto 0;
        text-align: center;
    }
     #button{
        &:hover{
            background: #333;
            color: #ccc;
        }
        width:150px;
        height:35px;
        line-height: 35px;
        background: #666;
        margin:25px auto;
        cursor: pointer;
    }
}

noscript{
    display: block;
    font-size: 0.9em;
    border:3px solid $brand-color;
    padding:10px;
    margin:10px auto 50px;
}